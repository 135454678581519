import { Button, Form, message, Modal, Popconfirm, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { navigate } from "gatsby-link";
import { filter, forEach, get, isEmpty, map, set } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import addPlacementDynamicCap from "../../../newApi/pairing/addPlacementDynamicCap.js";
import { updateActiveId } from "../../../redux/actions/product-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

import { orderBy } from "lodash";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getPackages from "../../../newApi/pairing/getPackages";
import getTopUp from "../../../newApi/pairing/getTopUp";
import getPlacements from "../../../newApi/placement/getPlacements";
import createTopUp from "../../../newApi/topup/createTopUp";
import RequirePairingAuthWrapper from "../../general/components/RequirePairingAuthWrapper";

//let displayRobot = [];

// markup
const DirectReward = (props) => {
  // const location = useLocation();
  const { t, languages } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [placementList, setPlacementList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [packageSelect, setPackageSelect] = useState(true);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [isFirstTopUp, setIsFirstTopUp] = useState(false);
  let accessKey = get(props.user, "accessKey");
  const user = get(props, "user.user");

  //let profile = get(props.user.user, "profile");

  useEffect(() => {
    getPackagesData();
    getTopUp(
      "all",
      0,
      {
        userId: get(user, "profile.pairingUserId"),
        amountNe: 0,
      },
      accessKey
    )
      .then((res) => {
        // console.log("tp", res);
        let data = get(res, "data");
        let length = data.length;
        if (length === 0) {
          setIsFirstTopUp(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getPlacementsData();
  }, [packageList]);

  useEffect(() => {
    let temp_data = orderBy(placementList, ["username"]);

    setPlacementList(temp_data);
  }, [selectedPackage]);

  function getPackagesData() {
    getPackages("all", 0, {}, accessKey)
      .then((res) => {
        // console.log("res", res);
        let data = get(res, "data");
        if (!isEmpty(data)) {
          setPackageList(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getPlacementsData() {
    // console.log("get data");
    if (!isEmpty(packageList)) {
      // console.log("here");

      setLoading(true);

      getPlacements(7, 0, {
        userId: get(user, "pairingUser._id"),
      })
        .then((res) => {
          let data = get(res, "data");

          let temp_data = [];
          if (!isEmpty(data)) {
            for (let i = 0; i < data.length; i++) {
              let placement = data[i];

              getTopUp(
                10,
                0,
                {
                  placementId: get(placement, "_id"),
                },
                accessKey
              )
                .then((res) => {
                  let topUp_data = get(res, "data");
                  if (isEmpty(topUp_data)) {
                    set(placement, "packageId", null);
                  } else {
                    set(
                      placement,
                      "packageId",
                      get(topUp_data[0], "packageId")
                    );
                    let packageData = filter(packageList, (item, index) => {
                      return item._id === get(topUp_data[0], "packageId");
                    });
                    set(placement, "packageInfo", packageData[0]);
                  }

                  temp_data.push(placement);
                  // console.log(temp_data);

                  if (i === data.length - 1) {
                    temp_data = orderBy(temp_data, ["username"]);

                    setPlacementList(temp_data);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }

  function upgradePackage(item) {
    // console.log(item);
    // console.log(form.getFieldValue("package"));

    setLoading(true);
    let selected_package = filter(packageList, (item, index) => {
      return item._id === form.getFieldValue("package");
    });
    // console.log(selected_package);
    selected_package = selected_package[0];
    let correctValues = {};
    correctValues = {
      placementId: get(item, "_id"),
      userId: get(item, "userId"),
      username: get(item, "username"),
      packageId: form.getFieldValue("package"),
      amount: get(selected_package, "amount"),
      buyerId: get(user, "pairingUser._id"),
      createdBy: get(user, "pairingUser._id"),
    };
    // console.log(correctValues);

    createTopUp(correctValues)
      .then((res) => {
        let topupData = get(res, "data");

        if (isFirstTopUp === true) {
          addPlacementDynamicCap(
            {
              placementId: get(item, "_id"),
              amount: 250,
              clientId: get(topupData, "_id"),
              remark: "Free 250",
            },
            accessKey
          )
            .then((res2) => {
              // console.log("addplacement", res2);
              message.success(t("topUpSuccess", sourceKey.pairing));
              setLoading(false);
              form.resetFields();
              navigate(routes.nodeTeam.to());
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          message.success(t("topUpSuccess", sourceKey.pairing));
          setLoading(false);
          form.resetFields();
          navigate(routes.nodeTeam.to());
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error(err?.message);
        console.log(err);
      });
  }

  const _renderList = () => {
    return (
      <div>
        <div className="flex justify-between m-4">
          <span className="inline-block w-1/3 flex justify-start text-lg font-semibold">
            {/* 账号名{" "}
            <MediaQuery maxWidth={400}>
              <br />
            </MediaQuery>{" "} */}
            {t("username", sourceKey.pairing)}
          </span>
          <span className="inline-block w-1/3 flex justify-start text-lg font-semibold">
            {/* 配套
            <MediaQuery maxWidth={400}>
              <br />
            </MediaQuery>{" "} */}
            {t("package", sourceKey.pairing)}
          </span>
          <span className="inline-block w-1/3"></span>
        </div>
        {map(placementList, (item, index) => {
          return (
            <div key={index} className="lightgreybox-bg-color m-4">
              <div className="flex justify-between p-4">
                <span className="inline-block w-1/3 flex justify-start">
                  {get(item, "username")}
                </span>
                <span className="inline-block w-1/3 flex justify-start">
                  {get(item, "packageId") === null ? (
                    t("notActivated", sourceKey.pairing)
                  ) : (
                    <>
                      {get(item, "packageInfo.name")}-
                      <MediaQuery maxWidth={400}>
                        <br />
                      </MediaQuery>
                      {get(item, "packageInfo.amount")}
                    </>
                  )}
                </span>
                <span className="inline-block w-1/3 flex justify-end">
                  <Popconfirm
                    title={
                      <>
                        {get(selectedPackage, "name")}-
                        {get(selectedPackage, "amount")}{" "}
                        {t("areYouSure", sourceKey.pairing)}
                      </>
                    }
                    onConfirm={() => {
                      // console.log("onclick");
                      upgradePackage(item);
                    }}
                  >
                    <Button
                      style={{ borderRadius: 10 }}
                      className="main-button-color"
                      // onClick={() => {
                      //   // setDrawerVisible(true);
                      //   // setSelectedData(item);
                      //   console.log("onclick");
                      //   upgradePackage(item);
                      // }}
                    >
                      {t("topUp", sourceKey.pairing)}
                    </Button>
                  </Popconfirm>
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  // console.log("selected data", selectedData);
  // console.log("placementList data", placementList);

  function submitPackage() {
    form
      .validateFields()
      .then((values) => {
        // console.log(values);
        // console.log("list", packageList);
        let selected = filter(packageList, (item, index) => {
          return get(item, "_id") === get(values, "package");
        });
        // console.log("before", selected);

        selected = selected[0];
        // console.log(selected);
        setSelectedPackage(selected);
        setPackageSelect(false);
      })
      .catch((err) => {
        forEach(get(err, "errorFields"), (item) => {
          message.error(get(item, "errors[0]"));
          setLoading(false);
        });
      });
  }

  const _renderModalContent = () => {
    return (
      <div
        className="lightgreybox-bg-color  pb-10 pt-5 rounded-3xl"
        // style={{ borderRadius: 15 }}
      >
        <Form form={form} autoComplete="off" style={{ color: "white" }}>
          <div className="rounded-3xl m-6 ">
            <div className="pb-2 font-semibold">
              {/* 请选择配套 Please Select a Package: */}
              {t("selectPackage", sourceKey.pairing)}
            </div>
            <Form.Item
              name="package"
              className="my-select-container"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: (
                    <React.Fragment>
                      {/* 请选择配套 <br /> */}
                      {t("selectPackage", sourceKey.pairing)}
                    </React.Fragment>
                  ),
                },
              ]}
            >
              {/* <div className="my-select-container"> */}
              <Select
                // showSearch
                className=""
                style={{ width: "100%" }}
                placeholder={t("selectPackage", sourceKey.pairing)}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {map(packageList, (item, index) => {
                  let description = get(item, "description");

                  return (
                    // <Select.Option
                    //   value={get(item, "_id") || ""}
                    //   key={`option-${index}`}
                    // >
                    //   {get(item, "name")} - {get(item, "amount")}
                    // </Select.Option>
                    <Select.Option
                      value={get(item, "_id") || ""}
                      title={get(item, "amount")}
                      key={`option-${index}`}
                    >
                      <div>
                        {get(item, "name")} - {get(item, "amount")}
                      </div>
                      <div className="" style={{ fontSize: "0.65rem" }}>
                        {get(item, "description")
                          ? get(description, languages)
                            ? `${get(description, languages)}`
                            : ""
                          : ""}
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
              {/* </div> */}
            </Form.Item>
          </div>
        </Form>
        <div className="flex justify-around mt-10">
          <span
            className="rounded-xl main-button-color text-center  py-1 cursor-pointer mx-4"
            onClick={() => {
              submitPackage();
            }}
            style={{ width: "100%" }}
          >
            {t("confirm")}
          </span>
          <span
            className="second-button-color border  py-1 text-center  rounded-xl cursor-pointer mx-4"
            style={{ width: "100%" }}
            onClick={() => {
              navigate(routes.rewardExplore.to());
            }}
          >
            {t("cancel")}
          </span>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <RequirePairingAuthWrapper>
            <NavHeader
              showBack={true}
              suffix={
                <React.Fragment>
                  <div className="justify-end flex items-center ">
                    {/* 配套升级{" "}
                      <MediaQuery maxWidth={440}>
                        <br />
                      </MediaQuery> */}
                    {t("packageUpgrade", sourceKey.pairing)}
                  </div>
                </React.Fragment>
              }
            >
              <div
                className=" pb-32"
                // style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
              >
                {/* <div className="pt-4">{_renderProfit()}</div> */}
                <div className="pt-2">{_renderList()}</div>
                <div className="flex justify-end items-center my-5">
                  <span className="inline-block ">
                    {/* <Pagination
                    simple
                    size="small"
                    total={total}
                    pageSize={PAGE_SIZE}
                    current={page}
                    showSizeChanger={false}
                    responsive={true}
                    onChange={(page) => {
                      setPage(page);
                    }}
                  /> */}
                  </span>
                </div>
              </div>

              <MediaQuery maxWidth={460}>
                <Modal
                  className="no-padding-modal-body modal-body-background-transparent"
                  centered
                  maskClosable
                  width="80%"
                  mask
                  footer={null}
                  closable={false}
                  visible={packageSelect}
                  style={{ borderRadius: "10px" }}
                >
                  {_renderModalContent()}
                </Modal>
              </MediaQuery>

              <MediaQuery minWidth={461}>
                <Modal
                  className="no-padding-modal-body modal-body-background-transparent"
                  centered
                  maskClosable
                  width="50%"
                  mask
                  footer={null}
                  closable={false}
                  visible={packageSelect}
                  style={{ borderRadius: "10px" }}
                >
                  {_renderModalContent()}
                </Modal>
              </MediaQuery>

              {/* <TopUpDrawer
              visible={drawerVisible}
              onClose={() => {
                setDrawerVisible(false);
                setSelectedData({});
              }}
              onSuccess={() => {
                getPlacements();
              }}
              data={selectedData}
              user={user}
              accessKey={accessKey}
            /> */}
            </NavHeader>
          </RequirePairingAuthWrapper>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  updateActiveId,
};
export default connect(mapStateToProps, mapDispatchToProps)(DirectReward);
