import { pairingPrefix } from ".";
import { apiUrl, _base_axios_post } from "..";

// const PAGESIZE = 30;

export default function addPlacementDynamicCap(query = {}, accessKey = "") {
  return _base_axios_post(
    `${apiUrl}/${pairingPrefix}/addPlacementDynamicCap`,
    query,
    accessKey
  );
}
